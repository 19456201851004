<template>
  <div>
    <canvas id="canvas" width="1000" height="1000" />
    <video id="setcollection"></video>
  </div>
</template>

<script>
import {
  Engine3D,
  Scene3D,
  Object3D,
  Camera3D,
  View3D,
  Vector3,
  LitMaterial,
  BoxGeometry,
  MeshRenderer,
  DirectLight,
  HoverCameraController,
  AtmosphericComponent,
  PlaneGeometry,
} from "@orillusion/core";
import {
  VideoMaterial,
  VideoTexture,
  ImageMaterial,
} from "@orillusion/media-extention";
import { getVideoinput, setCatchVideo } from "../utils/tools";
export default {
  data() {
    return {};
  },
  async mounted() {
    let canvas = document.getElementById("canvas");
    await Engine3D.init({
      canvasConfig: { canvas },
    });
    let scene3D = new Scene3D();
    // 添加大气散射天空组件
    scene3D.addComponent(AtmosphericComponent);
    // 新建摄像机实例
    let cameraObj = new Object3D();
    let camera = cameraObj.addComponent(Camera3D);
    // 根据窗口大小设置摄像机视角
    camera.perspective(100, window.innerWidth / window.innerHeight, 1, 5000.0);
    // 设置相机控制器
    let controller = camera.object3D.addComponent(HoverCameraController);
    controller.setCamera(0, 0, 20);
    // 添加相机节点
    scene3D.addChild(cameraObj);
    // 加载 gltf 文件
    // let data = await Engine3D.res.loadGltf("/modules/gltf_scene_test.gltf");
    let parser = await Engine3D.res.loadGltf(
      "https://mrstage-oss.oss-cn-shanghai.aliyuncs.com/nocode/20230713/gltf_scene_test2_HjczKqIAJu.gltf",
      {
        onProgress: (receivedLength, contentLength, url) => {
          // 监听下载进度
          console.log(receivedLength, contentLength, url, "监听下载进度");
        },
        onComplete: (url) => {
          // 文件下载完成
          console.log(url, "文件下载完成");
        },
        onError: (e) => {
          // 文件加载错误
          console.log(e, "文件加载错误");
        },
        onUrl: (url) => {
          // 可以根据需求，修改原始url，返回自定义路径
          console.log(url, "可以根据需求，修改原始url，返回自定义路径");
        },
      }
    );
    // 添加至场景
    scene3D.addChild(parser);

    let planeObj = new Object3D();
    let texture = await Engine3D.res.loadTexture(
      "https://cdn.orillusion.com/gltfs/cube/material_02.png"
    );

    // create a image material
    let mat = new ImageMaterial();
    mat.baseMap = texture;
    let mr = planeObj.addComponent(MeshRenderer);
    mr.geometry = new PlaneGeometry(2, 2, 1, 1, Vector3.Z_AXIS);
    mr.material = mat;
    planeObj.x = 0;
    planeObj.y = 2.5;
    planeObj.z = -1;
    scene3D.addChild(planeObj);

    // create a video texture
    let videoTexture = new VideoTexture();
    await videoTexture.load("https://cdn.orillusion.com/videos/bunny.mp4");
    // create a video material
    let matvideo = new VideoMaterial();
    matvideo.baseMap = videoTexture;
    // create a 2D plane to play the video
    let planevideo = new Object3D();
    let mrvideo = planevideo.addComponent(MeshRenderer);
    mrvideo.geometry = new PlaneGeometry(4, (4 * 9) / 16, 1, 1, Vector3.Z_AXIS);
    mrvideo.material = matvideo;
    planevideo.x = 3;
    planevideo.y = 2.5;
    planevideo.z = -1;
    scene3D.addChild(planevideo);
    // create a video texture
    const _videobox = document.querySelector("#setcollection");
    let arr = await getVideoinput();
    await setCatchVideo("setcollection", _videobox, arr[2].deviceId);
    setTimeout(async () => {
      let videoTexture2 = new VideoTexture();
      await videoTexture2.load(_videobox.srcObject);
      // create a video material
      let matvideo2 = new VideoMaterial();
      matvideo2.baseMap = videoTexture2;
      // create a 2D plane to play the video
      let planevideo2 = new Object3D();
      let mrvideo2 = planevideo2.addComponent(MeshRenderer);
      mrvideo2.geometry = new PlaneGeometry(
        4,
        (4 * 9) / 16,
        1,
        1,
        Vector3.Z_AXIS
      );
      mrvideo2.material = matvideo2;
      planevideo2.x = -3;
      planevideo2.y = 2.5;
      planevideo2.z = -1;
      scene3D.addChild(planevideo2);
    }, 3000);

    // 创建View3D对象
    let view = new View3D();
    // 指定渲染的场景
    view.scene = scene3D;
    // 指定使用的相机
    view.camera = camera;
    // 开始渲染
    Engine3D.startRenderView(view);
  },
  methods: {},
};
</script>

<style>
</style>