/**
 * 设置摄像头和切换摄像头
 * @param {Object} videoDom  摄像头对象
 * @param {Object} videoID   摄像头deviceId
 */
var cameraVideo = []
export async function setCatchVideo(domId, videoDom, videoID, config) {
  console.log(videoDom);
  if (!videoDom) {
    console.log('摄像头传入的domId不存在');
    return
  }
  let videoObj
  if (videoID === '') {
    // 设置默认获取的摄像头
    videoObj = {
      video: true
    }
  } else {
    // 切换摄像头
    videoObj = {
      video: { deviceId: videoID, width: 1920, height: 1080 }
    }
  }
  if (config) {
    videoObj.video = { ...videoObj.video, ...config }
  }
  const errBack = function (error) {
    console.log('视频捕获错误: ', error.code, error)
  }

  if (videoDom.tagName !== 'VIDEO') {
    var _video = videoDom.querySelector('video')
    if (!_video) {
      _video = document.createElement('video')
      _video.setAttribute('autoplay', true)
      _video.setAttribute('muted', true)
      _video.setAttribute('loop', true)
      _video.setAttribute('id', videoDom.id + '-video')
      _video.style.width = '100%'
      _video.style.height = '100%'
      videoDom.appendChild(_video)
    }

    videoDom = _video
  }
  if (navigator.getUserMedia) {
    // 正常的情况下
    navigator.getUserMedia(
      videoObj,
      (stream) => {
        if (cameraVideo.length) {
          if (cameraVideo.findIndex((item) => item.id === domId) !== -1) {
            cameraVideo[cameraVideo.findIndex((item) => item.id === domId)].stream.getTracks()[0].stop();
            cameraVideo[cameraVideo.findIndex((item) => item.id === domId)].stream = stream
          } else {
            cameraVideo.push({
              id: domId,
              stream: stream
            })
          }
        } else {
          cameraVideo.push({
            id: domId,
            stream: stream
          })
        }
        videoDom.srcObject = stream
        // videoDom.onloadedmetadata = function (e) {
        //   videoDom.play()
        // }
        // console.log(cameraVideo);
      },
      errBack
    )
  } else if (navigator.webkitGetUserMedia) {
    // WebKit
    navigator.webkitGetUserMedia(
      videoObj,
      (stream) => {
        videoDom.src = window.webkitURL.createObjectURL(stream)
        videoDom.onloadedmetadata = function (e) {
          videoDom.play()
        }
      },
      errBack
    )
  } else if (navigator.mozGetUserMedia) {
    // Firefox
    navigator.mozGetUserMedia(
      videoObj,
      (stream) => {
        videoDom.src = window.URL.createObjectURL(stream)
        videoDom.onloadedmetadata = function (e) {
          videoDom.play()
        }
      },
      errBack
    )
  }
}
export async function stopCameraVideo(val) {
  if (val === 'stopall') {
    cameraVideo.filter(item => {
      item.stream.getTracks()[0].stop();
      // document.getElementById(item.id).innerHTML = ''
    })
  } else {
    await cameraVideo.filter(async (item, index) => {
      if (item.id === val) {
        await item.stream.getTracks()[0].stop();
        document.getElementById(item.id).innerHTML = ''
        cameraVideo.splice(index, 1)
      }
    })
  }
}
// 获取相机源
export async function getVideoinput(config) {
  // 首次运行引导用户，信任域名
  var first = window.localStorage.getItem('first')
  if (first == null) {
    if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
      // 调用用户媒体设备, 访问摄像头
      getUserMedia({ video: { width: 5000, height: 5000 } }, success, error)
      // getUserMedia('', success, error)
    } else {
      alert('不支持访问用户媒体')
    }
  }
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    alert('浏览器不支持获取本地摄像机方法 ！')
    return
  }
  const videoList = []
  await navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      // console.log(devices);
      devices.forEach((device) => {
        if (device.kind === 'videoinput') {
          videoList.push(device)
        }
      })
    })
    .catch((err) => {
      alert(err.name + ': ' + err.message)
    })
  console.log('相机源', videoList)
  // await videoList.map((item, index) => {
  //   console.log(item.label, '相机源')
  // })
  return videoList
}

// 访问用户媒体设备的兼容方法
function getUserMedia(constraints, success, error) {
  if (navigator.mediaDevices.getUserMedia) {
    // 最新的标准API
    navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error)
  } else if (navigator.webkitGetUserMedia) {
    // webkit核心浏览器
    navigator.webkitGetUserMedia(constraints, success, error)
  } else if (navigator.mozGetUserMedia) {
    // firfox浏览器
    navigator.mozGetUserMedia(constraints, success, error)
  } else if (navigator.getUserMedia) {
    // 旧版API
    navigator.getUserMedia(constraints, success, error)
  }
}
function success(stream) {
  // console.log(stream)
  window.localStorage.setItem('first', 'false')
  // window.location.reload()
}
function error(error) {
  console.log(`访问用户媒体设备失败${error.name}, ${error.message}`)
}

export function uuId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0
    var v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}
export function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var randomString = ''
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length)
    randomString += charSet.substring(randomPoz, randomPoz + 1)
  }
  return randomString
}

export function openWindow(url) {
  window.open(url, 'newwindow', 'height=600,width=1200,top=500,left=600,scrollbars=yes,resizable=1,alwaysRaised=yes,toolbar=yes,menubar=yes')
}

export function applyCameraPermission() {
  // return promises
  return new Promise((resolve, reject) => {
    if (localStorage.getItem('CameraPermission')) {
      resolve(true)
      return
    }
    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: 6000,
        height: 6000
      }
    }).then(res => {
      localStorage.setItem('CameraPermission', 1)
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
